import { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from ".";
import AuthContext from "../context/AuthContext";
import CompanyPDFContext from "../context/CompanyPDFContext";
import { CompanyValues } from "../interface/Common";

export const useCreateCompany = () => {
  const authctx = useContext(AuthContext);
  const companyDet = useContext(CompanyPDFContext);

  const [data, callApi, isLoading, errMessage] = usePost(
    authctx.companyDetails.tokenAccess,
    true
  );
  const [response, setResponse] = useState("");
  const [companyID, setCompanyId] = useState("");

  const newCompany = useCallback((formValues: CompanyValues | any) => {
    const formData = new FormData();
    for (const key of Object.keys(formValues)) {
      if (key === "shareholderObj") {
        for (const newKey in formValues[key]) {
          delete formValues[key][newKey].htmlid;

          formData.append(
            `shareholder_id_copy_${newKey}`,
            formValues[key][newKey]["shareholder_id_copy"]
          );

          let shareHoldData = { ...formValues[key][newKey] };
          delete shareHoldData.shareholder_id_copy;
          shareHoldData.binary_id = `shareholder_id_copy_${newKey}`;

          formData.append("shareholders", JSON.stringify(shareHoldData));
        }
      }

      if (
        key !== "directors_values" &&
        key !== "fileRes" &&
        key !== "shareholderObj"
      ) {
        formData.append(key, formValues[key]);
      }
    }
    callApi(formData, `auth/company-details/`);
  }, []);

  useEffect(() => {
    if ("error" in data) {
      setResponse("error company");
    } else if ("company_details" in data) {
      setResponse("company added");
      setCompanyId(data.company_details.uuid);
      companyDet.addCompanyDetails(data.company_details);
      companyDet.setShareholdersResponse(data.shareholders);
    }
  }, [data]);

  return [response, companyID, newCompany, isLoading, errMessage] as const;
};
