import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

export default function SignDocs() {
  const ctxAuth = useContext(AuthContext);
  // const outlet = useOutlet();

  // let path = outlet?.props?.children?.props?.match?.pathname;

  if (!ctxAuth.userDetails.hasCompany) {
   
    return <Navigate to="/company-setup" />;
  }

  return <Outlet />;
}
