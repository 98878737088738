import React, { useState } from "react";
import { Accounts, message_type } from "../interface/Common";
import { currency_details } from "../interface/";
import { useNavigate } from "react-router-dom";

type selectedAcc = {
  currency: string;
  uuid: string;
};

type selectedAcc2 = {
  [currency: string]: selectedAcc;
};

type pay = {
  exchange: currency_details;
  bankSelected: selectedAcc2;
  setBankSelected: (currency: string, uuid: string) => void;
  exchangeSet: (data: any) => void;
  onClear: () => void;
  AddAccounts: (account: Accounts) => void;
  blocker: boolean;
  message: { title: string; message: string; mess_type: string };
};

const payment: pay = {
  exchange: {
    from: "",
    to: "",
    amount: 0,
    amount_to_receive: 0,
    accSent: "",
    accSentName: "",
    accRec: "",
    accRecName: "",
    rate: 0,
    sourceOfFunds: "",
    sourceOfFundsDocs: [],
    reasonForExchange: "",
    supportingDocs: [],
    transactionCode: "",
    transactionDocs: [],
  },
  bankSelected: {},
  setBankSelected: (currency: string, uuid: string) => {},
  exchangeSet: (data: any) => {},
  onClear: () => {},
  AddAccounts: (account: Accounts) => {},
  blocker: false,
  message: { title: "", message: "", mess_type: "" },
};

const PaymentContext = React.createContext(payment);

type Props = {
  children: JSX.Element;
};

export const PaymentContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [exchange, setExchange] = useState<currency_details>({
    from: "",
    to: "",
    amount: 0,
    amount_to_receive: 0,
    accSent: "",
    accSentName: "",
    accRec: "",
    accRecName: "",
    rate: 0,
    sourceOfFunds: "",
    sourceOfFundsDocs: [],
    reasonForExchange: "",
    supportingDocs: [],
    transactionCode: "",
    transactionDocs: [],
  });
  const [mess, setMess] = useState<message_type>({
    title: "",
    message: "",
    mess_type: "",
  });

  const [blocker, setBlocker] = useState(false);
  const exchangeSet = (data: any) => {
    setExchange((prev) => {
      return { ...prev, ...data };
    });
  };

  const [bankSelected, setBankSelected] = useState<selectedAcc2>({});

  const onClear = () => {
    setExchange({
      from: "",
      to: "",
      amount: 0,
      amount_to_receive: 0,
      accSent: "",
      accSentName: "",
      rate: 0,
      accRec: "",
      accRecName: "",
      sourceOfFunds: "",
      sourceOfFundsDocs: [],
      reasonForExchange: "",
      supportingDocs: [],
      transactionCode: "",
      transactionDocs: [],
    });
    setBankSelected({});
    setMess({ title: "", message: "", mess_type: "" });
    setBlocker(false);
    navigate("/dashboard");
  };

  const AddAccounts = (account: Accounts) => {

    if (account.type === "accSent") {
      const dt = { accSent: account.uuid };
      const name = {
        accSentName: `${account.name},\n${account.accountNumber},\n${account.bankName},\n${account.branchName}`,
      };

      setExchange((prev) => {
        return { ...prev, ...dt, ...name };
      });
    } else {
      const dt = { accRec: account.uuid };
      const name = {
        accRecName: `${account.name},\n${account.accountNumber},\n${account.bankName},\n${account.branchName} `,
      };

      setExchange((prev) => {
        return { ...prev, ...dt, ...name };
      });
    }
  };

  const activeAcc = (currency: string, uuid: string) => {
    setBankSelected((prev: selectedAcc2) => {
      return { ...prev, [currency]: { currency: currency, uuid: uuid } };
    });
  };

  return (
    <PaymentContext.Provider
      value={{
        exchange: exchange,
        blocker: blocker,
        bankSelected: bankSelected,
        setBankSelected: activeAcc,
        message: mess,
        exchangeSet: exchangeSet,
        AddAccounts: AddAccounts,
        onClear: onClear,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;
